import React, { CSSProperties } from 'react'
import { DomElement } from 'htmlparser2'
import { ITransformerStrategy } from '../textTransformer.utils'

const styles: CSSProperties = {
  display: 'inline-block',
  position: 'relative',
  top: '-1.3em',
  fontSize: '0.4em',
  lineHeight: 1.8,
}

export const supTransformer: ITransformerStrategy = {
  testCondition(node: DomElement) {
    return node.name === 'sup'
  },
  execute(node: DomElement) {
    if (Array.isArray(node.children)) {
      return <span style={styles}>{node.children[0].data}</span>
    }
  },
}
