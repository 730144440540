import { ReactElement } from 'react'
import { Transform } from 'react-html-parser'
import { DomElement } from 'htmlparser2'
import { supTransformer } from './transformers/sup.transformer'
import { newLineTransformer } from './transformers/newLine.transformer'

export interface ITransformerStrategy {
  testCondition(node: DomElement): boolean
  execute: Transform
}

export interface ITextTransformer {
  transform: Transform
}

const defaultTransformer = {
  testCondition(): boolean {
    return true
  },
  execute: (): void => {},
}

class TextTransformer implements ITextTransformer {
  private strategies: ITransformerStrategy[] = [supTransformer, newLineTransformer, defaultTransformer]

  transform = (node: DomElement, index: number): ReactElement | void | null => {
    const strategy: ITransformerStrategy = this.strategies.find((strategy: DomElement) =>
      strategy.testCondition(node),
    ) as ITransformerStrategy

    return strategy.execute(node, index)
  }
}

export const textTransformer = new TextTransformer()
