import React, { PropsWithChildren } from 'react'
import { Text as RText, SxStyleProp, TextProps as RTextProps } from 'rebass'
import parseHtml from 'react-html-parser'
import { textTransformer } from '../utils/textTransformer/textTransformer.utils'
import { theme } from '../../../styles/theme'

export enum TEXT_TAG {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  p = 'p',
  span = 'span',
}

export enum TEXT_FONT_COLOR {
  lightGrey = 'lightGrey',
  mediumGrey = 'mediumGrey',
  darkGrey = 'darkGrey',
  goldDark = 'goldDark',
  goldPrimary = 'goldPrimary',
  goldTint1 = 'goldTint1',
  goldTint2 = 'goldTint2',
  goldTint3 = 'goldTint3',
  goldTint4 = 'goldTint4',
  copperDark = 'copperDark',
  copperPrimary = 'copperPrimary',
  copperTint1 = 'copperTint1',
  copperTint2 = 'copperTint2',
  copperTint3 = 'copperTint3',
  copperTint4 = 'copperTint4',
  blackRich = 'blackRich',
  blackPrimary = 'blackPrimary',
  blackTint1 = 'blackTint1',
  blackTint2 = 'blackTint2',
  blackTint3 = 'blackTint3',
  libertyGreenPrimary = 'libertyGreenPrimary',
  libertyGreenTint1 = 'libertyGreenTint1',
  libertyGreenTint2 = 'libertyGreenTint2',
  libertyGreenTint3 = 'libertyGreenTint3',
  libertyGreenTint4 = 'libertyGreenTint4',
  canyonPrimary = 'canyonPrimary',
  canyonTint1 = 'canyonTint1',
  canyonTint2 = 'canyonTint2',
  canyonTint3 = 'canyonTint3',
  canyonTint4 = 'canyonTint4',
  brickPrimary = 'brickPrimary',
  brickTint1 = 'brickTint1',
  brickTint2 = 'brickTint2',
  brickTint3 = 'brickTint3',
  brickTint4 = 'brickTint4',
  white = 'white',
}

export enum TEXT_FONT_STYLE {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h3Serif = 'h3Serif',
  h4 = 'h4',
  h5 = 'h5',
  bodyXXlarge = 'bodyXXlarge',
  bodyXXlargeLight = 'bodyXXlargeLight',
  bodyXlarge = 'bodyXlarge',
  bodyXlargeBold = 'bodyXlargeBold',
  bodyXlargeLight = 'bodyXlargeLight',
  bodyXlargeSemibold = 'bodyXlargeSemibold',
  bodyLarge = 'bodyLarge',
  bodyLargeBold = 'bodyLargeBold',
  bodyMedium = 'bodyMedium',
  bodyMediumBold = 'bodyMediumBold',
  bodySmall = 'bodySmall',
  bodySmallBold = 'bodySmallBold',
  bodyXsmall = 'bodyXsmall',
  bodyLargeLight = 'bodyLargeLight',
  numeralsHeader = 'numeralsHeader',
  menuSecondary = 'menuSecondary',
  menuTertiary = 'menuTertiary',
  eyebrow = 'eyebrow',
  heroP = 'heroP',
  smallPrint = 'smallPrint',
  inlineLabel = 'inlineLabel',
  ctaButton = 'ctaButton',
  legalDisclaimers = 'legalDisclaimers',
  textBlockList = 'textBlockList',
  textBlockListBold = 'textBlockListBold',
}

export interface ICMSTextV2Props extends ITextV2Props {
  text?: string
}

export interface ITextV2Props extends RTextProps {
  tag?: TEXT_TAG
  textFontStyle?: TEXT_FONT_STYLE
  textFontColor?: TEXT_FONT_COLOR
  text?: string
  mobileText?: string
  sx?: SxStyleProp
}

export const TextV2 = (props: PropsWithChildren<ITextV2Props>) => {
  const {
    tag = 'p',
    textFontStyle = 'bodyMedium',
    textFontColor = 'blackPrimary',
    text,
    mobileText,
    sx,
    children,
  } = props

  return (
    <>
      <RText
        as={tag}
        sx={{
          display: mobileText ? ['none', 'none', 'block'] : 'block',
          color: theme.v2.colors[textFontColor],
          '& > a': {
            color: theme.v2.colors.copperDark,
          },
          ...(theme.v2.fontStyles[textFontStyle] as Record<string, string>),
          ...sx,
        }}
      >
        {parseHtml((children || text) as string, textTransformer)}
      </RText>

      {/* This is the mobile text if it exists */}
      {mobileText && (
        <RText
          as={tag}
          sx={{
            display: ['block', 'block', 'none'],
            color: theme.v2.colors[textFontColor],
            '& > a': {
              color: theme.v2.colors.copperDark,
            },
            ...(theme.v2.fontStyles[textFontStyle] as Record<string, string>),
            ...sx,
          }}
        >
          {parseHtml(mobileText, textTransformer)}
        </RText>
      )}
    </>
  )
}
