import React from 'react'
import { DomElement } from 'htmlparser2'
import { ITransformerStrategy } from '../textTransformer.utils'

export const newLineTransformer: ITransformerStrategy = {
  testCondition(node: DomElement) {
    return node.type === 'text' && node.data.includes('\n')
  },
  execute(node: DomElement) {
    const chunks = node.data.split(/\n/)

    return chunks.map((chunk: string, index: number) => (
      <>
        <span>{chunk}</span>
        {index !== chunks.length - 1 ? <br /> : null}
      </>
    ))
  },
}
